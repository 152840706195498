<template>
  <div class="tiny-wish">
    <!-- <van-nav-bar title="微心愿" left-arrow @click-left="onClickLeft" /> -->
    <ul class="tab-title">
      <li v-for="(item,index) in tabList" :key="index">
        <span
          @click="changeList(item.type)"
          :class="[activeType==item.type ? 'clickStyle' : '']"
        >{{item.title}}</span>
      </li>
    </ul>
    <div class="tiny-wish-body">
      <!-- <All v-if="activeType=='all'" />
      <MyWish v-else-if="activeType=='mywish'" />
      <Get v-else />-->
      <ul class="tiny-wish-list">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :offset="70"
            :error.sync="error"
            error-text="请求失败，点击重新加载"
          >
            <li
              v-for="(item,index) in wishList"
              :key="index"
              @click="goDetail(item.wishStatus,item.id)"
            >
              <div class="img" :class="[item.materialId || 'defaultImg' ]">
                <img :src="item.materialId" alt />

                <!-- <van-image fit="cover" :src="item.materialId" style="width:100%;height:100%" /> -->
              </div>
              <div class="desc">
                <h4>{{item.wishTitle}}</h4>
                <!-- 无 -->
                <p>{{item.description}}</p>
              </div>
              <div class="record">
                <div>
                  <p>
                    许愿人：{{item.wishPersonName}}
                    &nbsp;&nbsp;
                    <!-- <img
                      v-if="item.wishStatus!==2 && item.wishPersonName!==realName && item.givePersonnelName==realName"
                      src="./../../assets/img/contact.png"
                      alt
                      @click.stop="toCall(item.wishPersonMobile)"
                    />-->
                  </p>
                  <p>发布时间：{{item.startTime}}</p>
                  <p>截至时间：{{item.endTime}}</p>
                </div>
                <!-- <button :class="[item.wishStatus? 'hadget': '']">{{item.wishStatus ? '已认领': '立即领取'}}</button> -->
                <button
                  :class="[wishStatus(item.wishStatus)]"
                  v-show="item.wishStatus!==5"
                >{{item.wishStatusName}}</button>
              </div>
              <img
                src="./../../assets/img/checked.png"
                alt
                srcset
                class="reject"
                v-show="item.wishStatus==5"
              />
            </li>
          </van-list>
        </van-pull-refresh>
      </ul>
    </div>
    <div class="write-letter" @click="toWishFn">
      <img src="./../../assets/img/wish.png" alt />
    </div>
    <!-- 确认是否与他联系框 -->
    <van-overlay :show="show">
      <div class="wrapper" @click.stop="show=false">
        <div class="block">
          <h3>温馨提示</h3>
          <p class="call">是否确定拨打电话？</p>
          <p class="is">
            <span @click.stop="toCall">是</span>
            <span @click.stop="show=false">否</span>
          </p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { getWishList, getAuditList, requestConfig } from '@/api/tinyWish';
import uncheckedDetailVue from './uncheckedDetail.vue';
import * as dd from 'dingtalk-jsapi';
export default {
  name: 'tinyWish',
  data() {
    return {
      activeType: 1,
      tabList: [
        {
          id: 1,
          type: 1,
          title: '全部'
        },
        {
          id: 2,
          type: 2,
          title: '我的心愿'
        },
        {
          id: 3,
          type: 3,
          title: '领办'
        }
      ],
      wishList: [],
      currentPage: 1,
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      realName: '',
      phone: 0, // 记录联系号码
      show: false, // 打开确认框
      getRecordStatus: false // 查询
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ name: 'mainPage' });
    },
    //我要许愿
    toWishFn() {
      this.$router.push({
        name: 'postMyWish',
        params: { user: this.realName }
      });
    },
    //心愿状态
    wishStatus(status) {
      switch (status) {
        case 1:
          return '';
          break;
        case 2:
          return '';
          break;
        case 3:
          return '';
          break;
        default:
          return 'hadget';
          break;
      }
    },
    //跳转至详情页面
    goDetail(status, id) {
      this.$router.push({ name: 'finishWish', params: { id: id } });
    },
    //请求更多心愿数据
    getMoreWishPage() {
      this.getRecordStatus = true;
      //请求普通用户列表
      getWishList({
        type: this.activeType,
        size: 4,
        current: this.currentPage
      })
        .then(res => {
          if (res.success) {
            //加载完成
            this.currentPage++;
            if (res.data.records.length === 0) {
              this.finished = true;
            }
            this.loading = false;
            this.error = false;
            let arr = [...this.wishList, ...res.data.records];
            this.wishList = arr;
          } else {
            this.error = true;
          }
          this.getRecordStatus = false;
        })
        .catch(err => {
          this.error = true;
          console.log(err);
          this.getRecordStatus = false;
        });
    },
    //请求第一页数据
    getFirstWishPage() {
      this.getRecordStatus = true;
      //请求普通用户列表
      getWishList({
        type: this.activeType,
        size: 4,
        current: 1
      })
        .then(res => {
          console.log(res);
          if (res.success) {
            this.wishList = res.data.records;
            if (res.data.records.length !== 4) {
              this.finished = true;
            }
            this.loading = false;
            this.error = false;
            this.refreshing = false;
          } else {
            this.error = true;
          }
          this.getRecordStatus = false;
        })
        .catch(err => {
          this.error = true;
          this.getRecordStatus = false;
          console.log(err);
        });
    },
    //加载更多数据
    onLoad() {
      // if (!this.getRecordStatus) {
      //   setTimeout(() => {
      //     if (this.refreshing) {
      //       this.getFirstWishPage();
      //       this.currentPage = 2;
      //     } else {
      //       this.getMoreWishPage();
      //     }
      //     this.loading = false;
      //   }, 500);
      // }
      setTimeout(() => {
        if (this.refreshing) {
          this.getFirstWishPage();
          this.currentPage = 2;
          this.refreshing = false;
        } else {
          this.getMoreWishPage();
        }
        this.loading = false;
      }, 500);
    },
    //刷新数据
    onRefresh() {
      // 清空列表数据
      this.wishList = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    //点击tab时切换
    changeList(type) {
      this.activeType = type;
      this.currentPage = 1;
      console.log(this.currentPage);
      this.wishList = [];
      this.finished = false;
      this.getFirstWishPage();
      this.currentPage = 2;
    },
    //打开与他联系框
    // openCallBox(wishPersonMobile) {
    //   this.phone = wishPersonMobile;
    // },
    //与他联系
    toCall(wishPersonMobile) {
      this.phone = wishPersonMobile;
      dd.biz.telephone.showCallMenu({
        phoneNumber: this.phone, // 期望拨打的电话号码
        code: '+86', // 国家代号，中国是+86
        showDingCall: true, // 是否显示钉钉电话
        onSuccess: function() {
          console.log('成功');
        },
        onFail: function() {
          console.log('失败');
        }
      });
    }
  },
  computed: {
    //列表类型筛选
    wishType() {
      switch (this.activeType) {
        case 2:
          return this.wishList.filter(elm => elm.type == 2);
          break;
        case 3:
          return this.wishList.filter(elm => elm.type == 3);
          break;
        default:
          return this.wishList;
          break;
      }
    }
  },
  // 如果从首页返回就退出
  beforeRouteEnter(to, from, next) {
    let selectRow = 1;
    if (from.name === 'finishWish') {
      selectRow = Number(localStorage.getItem('wishListSelectRow'));
    }
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。

      vm.activeType = selectRow; //当前组件的实例
    });
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    const selectRow = this.activeType;
    localStorage.setItem('wishListSelectRow', selectRow);
    next();
  },
  created() {
    // this.loading = true;
    // this.getFirstWishPage();
    this.realName = JSON.parse(localStorage.getItem('user')).realName;
  },
  mounted() {
    // requestConfig().then(res => {
    //   console.log(res);
    //   dd.config({
    //     agentId: res.data.agentId, // 必填，微应用ID
    //     corpId: res.data.corpId, //必填，企业ID
    //     timeStamp: res.data.timeStamp, // 必填，生成签名的时间戳
    //     nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
    //     signature: res.data.signature, // 必填，签名
    //     type: 0, //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
    //     jsApiList: [
    //       'runtime.info',
    //       'biz.contact.choose',
    //       'device.notification.confirm',
    //       'device.notification.alert',
    //       'device.notification.prompt',
    //       'biz.ding.post',
    //       'biz.util.openLink',
    //       'biz.telephone.showCallMenu'
    //     ] // 必填，需要使用的jsapi列表，注意：不要带dd。
    //   });
    //   dd.ready(function() {
    //     console.log('ready');
    //   });
    //   dd.error(function(error) {
    //     console.log(JSON.stringify(error));
    //   });
    // });
  }
};
</script>
<style scoped lang="scss">
$px: 1rem/75;
.tiny-wish {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .tab-title {
    height: 88 * $px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    li {
      font-size: 28 * $px;
      height: 100%;
      line-height: 88 * $px;
      span {
        display: inline-block;
        height: 100%;
        box-sizing: border-box;
        &.clickStyle {
          border-bottom: 2 * $px solid #ff192f;
          color: #ff192f;
        }
      }
    }
  }
  .tiny-wish-body {
    flex: 1;
    overflow: scroll;
    background: #f3f3f3;
    .tiny-wish-list {
      padding: 0 20 * $px;
      li {
        position: relative;
        background: #fff;
        margin-top: 20 * $px;
        border-radius: 8 * $px;
        padding: 20 * $px;
        font-size: 24 * $px;
        .img {
          background: url('./../../assets/img/list.png') center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .defaultImg {
          width: 335px;
          height: 130px;
        }
        .desc {
          border-bottom: 2 * $px solid #f3f3f3;
          padding-bottom: 20 * $px;
          h4 {
            font-size: 32 * $px;
            font-family: PingFang SC;
            font-weight: 600;
            color: rgba(34, 34, 34, 1);
            margin: 18 * $px 0;
          }
          p {
            font-family: PingFang SC;
            font-weight: 400;
            color: rgba(85, 85, 85, 1);
            width: 568 * $px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .record {
          position: relative;
          padding-top: 15 * $px;
          p {
            margin-bottom: 10 * $px;

            & > img {
              display: inline-block;
              width: 132 * $px;
              height: 32 * $px;
            }
          }
          button {
            position: absolute;
            right: 10 * $px;
            bottom: 0;
            width: 140 * $px;
            height: 48 * $px;
            border: 2 * $px solid rgba(255, 25, 47, 1);
            color: rgba(255, 25, 47, 1);
            background: #fff;
            border-radius: 24 * $px;
            font-size: 24 * $px;
            &.hadget {
              border: 2 * $px solid rgba(153, 153, 153, 1);
              color: rgba(153, 153, 153, 1);
              background: #fff;
            }
          }
        }
        .reject {
          position: absolute;
          bottom: 30 * $px;
          right: 30 * $px;
          width: 140 * $px;
          height: 140 * $px;
        }
      }
    }
  }
  .write-letter {
    position: fixed;
    width: 143 * $px;
    height: 143 * $px;
    right: 28 * $px;
    bottom: 28 * $px;
    z-index: 999;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 600 * $px;
      height: 300 * $px;
      background-color: #fff;
      border-radius: 8 * $px;
      padding: 30 * $px;
      box-sizing: border-box;
      h3 {
        font-size: 40 * $px;
        padding-left: 20 * $px;
      }
      p {
        font-size: 34 * $px;
        &.call {
          margin: 40 * $px;
          margin-left: 20 * $px;
        }
        &.is {
          margin: 10 * $px 0 0 280 * $px;
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
}
</style>